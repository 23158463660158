import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import { useCurrentCompanyGroup } from "../../../../admin/presentation/hooks/useCurrentCompanyGroup";
import { IEnum } from "../../../../core/domain/entities/enum";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { SoulSpinner } from "../../../../core/presentation/components/SoulSpinner";
import { useSoulDialog } from "../../../../core/presentation/hooks/useSoulDialog";
import { ProviderEnums } from "../../../domain/contracts/getProviderEnumsContract";
import { IProviderEntity } from "../../../domain/entities/providerEntity";
import {
  ProviderBankForm,
  ProviderForm,
} from "../../../domain/entities/providerForm";
import { MakeProvider } from "../../../main/makeProvider";
import { AddressForm } from "../AddressForm";
import { BankForm } from "../BankForm";
import { ForeignPersonForm } from "../ForeignPersonForm";
import { IndividualPersonForm } from "../IndividualPersonForm";
import { LegalPersonForm } from "../LegalPersonForm";
import { SharedForm } from "../SharedForm";
import { Container } from "./styles";

interface ProviderFormModalProps {
  isOpen: boolean;
  currentId: string;
  useProvider: MakeProvider;
  bankDataRequired?: boolean;
  onRequestClose: () => void;
  onProviderCreated?(providerEntity: IProviderEntity): void;
}

export interface ProviderFormModalState {
  isLoading: boolean;
  enums: Record<ProviderEnums, IEnum[]>;
  defaultCountry: ITypeaheadOption | null;
}

export function ProviderFormModal(props: ProviderFormModalProps) {
  const {
    isOpen,
    currentId,
    useProvider,
    onRequestClose,
    onProviderCreated,
    bankDataRequired = false,
  } = props;

  const {
    getProvider,
    saveProvider,
    searchCountries,
    getProviderEnums,
    updateProviderBankData,
  } = useProvider;

  const dialog = useSoulDialog();
  const { currentCompanyGroup } = useCurrentCompanyGroup();
  const form = useForm<ProviderForm>({
    mode: "all",
    defaultValues: {
      id: "",
      active: true,
      hasCompanyTax: false,
      companyGroupId: currentCompanyGroup.id,
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { isValid, isSubmitting, dirtyFields },
  } = form;

  const [state, setState] = useState<ProviderFormModalState>({
    isLoading: false,
    defaultCountry: null,
    enums: {
      banks: [],
      sexTypes: [],
      providerTypes: [],
      maritalStatus: [],
      professorTypes: [],
      teacherRegimes: [],
      bankAccountTypes: [],
    },
  });

  const canClose = !(state.isLoading || isSubmitting);
  const { name: companyGroupName } = currentCompanyGroup;

  const getDefaultCountryId = async () => {
    const response = await searchCountries({
      search: "Brasil",
      activesOnly: true,
    });

    if (!response?.length) {
      return null;
    }

    return response[0];
  };

  const getProviderAndBuildForm = async () => {
    const provider = await getProvider(currentId);
    const providerEntries = Object.entries(provider).filter(([, value]) => {
      return value !== null && value !== undefined;
    });

    const mappedProvider = new Map(providerEntries);

    if (provider?.countryId && provider?.countryName) {
      mappedProvider.set("country", {
        label: provider.countryName,
        rawValue: provider.countryId,
      });
    }

    if (provider?.stateId && provider?.stateName) {
      mappedProvider.set("state", {
        label: provider.stateName,
        rawValue: provider.stateId,
      });
    }

    if (provider?.cityId && provider?.cityName) {
      mappedProvider.set("city", {
        label: provider.cityName,
        rawValue: provider.cityId,
      });
    }

    mappedProvider.delete("countryId");
    mappedProvider.delete("countryName");
    mappedProvider.delete("stateId");
    mappedProvider.delete("stateName");
    mappedProvider.delete("cityId");
    mappedProvider.delete("cityName");

    const providerForm = Object.fromEntries(mappedProvider.entries());

    reset(providerForm);
  };

  const handleAfterOpen = async () => {
    setState(prevState => ({ ...prevState, isLoading: true }));

    try {
      const enums = await getProviderEnums();
      const defaultCountry = await getDefaultCountryId();

      setState(prevState => ({
        ...prevState,
        enums,
        defaultCountry,
      }));

      if (currentId) {
        await getProviderAndBuildForm();
      }
    } catch {
      onRequestClose();
    } finally {
      setState(prevState => ({ ...prevState, isLoading: false }));
    }
  };

  const handleAfterClose = () => {
    reset({
      id: "",
      active: true,
      hasCompanyTax: false,
      companyGroupId: currentCompanyGroup.id,
    });
  };

  const handleBankDataUpdate = async (data: ProviderForm) => {
    const dirtyBankForm = Object.keys(dirtyFields).some(fieldName => {
      const bankFormFields: Array<keyof ProviderBankForm> = [
        "bank",
        "bankBranch",
        "bankAccount",
        "documentHolder",
        "holderName",
        "bankBranchDigit",
        "bankAccountDigit",
        "bankAccountType",
        "type",
      ];

      return bankFormFields.includes(fieldName as keyof ProviderBankForm);
    });

    if (dirtyBankForm) {
      await updateProviderBankData(data);
    }
  };

  const onValid = async (data: ProviderForm) => {
    const msg = currentId
      ? "Fornecedor atualizado com sucesso."
      : "Fornecedor cadastrado com sucesso.";

    try {
      const providerData = await saveProvider(data);

      if (currentId) {
        await handleBankDataUpdate(data);
      }

      await dialog.fire({
        text: msg,
        title: "Feito!",
        icon: "success",
        confirmButtonText: "OK",
      });

      onProviderCreated?.(providerData);
      onRequestClose();
    } catch (err) {
      dialog.close();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnEsc={canClose}
      onAfterOpen={handleAfterOpen}
      className="react-modal-content"
      onAfterClose={handleAfterClose}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={canClose}
      overlayClassName="react-modal-overlay"
    >
      <Container>
        <div className="react-modal-header">
          <h4>{currentId ? "Editar Fornecedor" : "Novo Fornecedor"}</h4>
          <button
            type="button"
            id="btn-cross"
            disabled={!canClose}
            data-testid="btn-cross"
            className="react-modal-close"
            onClick={onRequestClose}
          >
            <IoMdClose />
          </button>
        </div>
        {state.isLoading ? (
          <div className="loading-container">
            <SoulSpinner />
          </div>
        ) : null}
        {!state.isLoading ? (
          <>
            <div className="react-modal-body">
              <div className="crud-header">
                <p>Este registro será vinculado ao grupo de empresa</p>
                <h3>{companyGroupName}</h3>
              </div>
              <FormProvider {...form}>
                <form
                  id="provider-form"
                  className="form-container"
                  onSubmit={handleSubmit(onValid)}
                >
                  <SharedForm
                    defaultCountry={state.defaultCountry}
                    providerTypes={state.enums.providerTypes}
                    teacherRegimes={state.enums.teacherRegimes}
                    professorTypes={state.enums.professorTypes}
                  />
                  <ForeignPersonForm />
                  <LegalPersonForm useProvider={useProvider} />
                  <IndividualPersonForm
                    useProvider={useProvider}
                    sexTypes={state.enums.sexTypes}
                    maritalStatus={state.enums.maritalStatus}
                  />
                  <BankForm
                    useProvider={useProvider}
                    banks={state.enums.banks}
                    bankDataRequired={bankDataRequired}
                    bankAccountTypes={state.enums.bankAccountTypes}
                  />
                  <AddressForm
                    providerId={currentId}
                    useProvider={useProvider}
                    defaultCountry={state.defaultCountry}
                  />
                </form>
              </FormProvider>
            </div>
            <div className="react-modal-footer">
              <button
                type="button"
                id="btn-close"
                disabled={isSubmitting}
                className="form-button red-bkg"
                onClick={onRequestClose}
              >
                Fechar
              </button>
              <button
                type="submit"
                id="btn-save"
                form="provider-form"
                disabled={isSubmitting}
                data-testid="btn-save"
                className={`form-button ${
                  isValid ? "green-bkg" : "invalid-bkg"
                }`}
              >
                Salvar {isSubmitting && <i className="pi pi-spin pi-spinner" />}
              </button>
            </div>
          </>
        ) : null}
      </Container>
    </Modal>
  );
}
