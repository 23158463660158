import { IApiService } from "../../../../core/data/services/apiService";
import { DecryptService } from "../../../../core/data/services/decryptService";
import { GetUserLocalService } from "../../../../core/data/services/getUserLocalService";
import { SearchClassificationUspService } from "../../../../core/data/services/searchClassificationUspService";
import { SearchCompanyService } from "../../../../core/data/services/searchCompanyService";
import { SearchCompetencyService } from "../../../../core/data/services/searchCompetencyService";
import { SearchProjectService } from "../../../../core/data/services/searchProjectService";
import { SetServerSideService } from "../../../../core/data/services/setServerSideService";
import { IListAttachmentTypesContract } from "../../../../core/domain/contracts/listAttachmentTypesContract";
import { ISearchClassificationUspContract } from "../../../../core/domain/contracts/searchClassificationUspContract";
import { ISearchCompanyContract } from "../../../../core/domain/contracts/searchCompanyContract";
import { ISearchCompetencyContract } from "../../../../core/domain/contracts/searchCompetencyContract";
import { ISearchProjectContract } from "../../../../core/domain/contracts/searchProjectContract";
import { ChangeInconsistentStatusService } from "../data/services/changeInconsistentStatusService";
import { CheckDocumentNumberService } from "../data/services/checkDocumentNumberService";
import { CustomerHasClassificationAccountService } from "../data/services/customerHasClassificationAccountService";
import { FetchDocumentStatusService } from "../data/services/fetchDocumentStatusService";
import { FetchPaymentMethodsService } from "../data/services/fetchPaymentMethodsService";
import { GetAccountReceivableService } from "../data/services/getAccountReceivableService";
import { GetAttachmentsService } from "../data/services/getAttachmentsService";
import { GetCustomerByIdService } from "../data/services/getCustomerByIdService";
import { GetStorageFilebyIdService } from "../data/services/getStorageFilebyIdService";
import { ListActiveCostCentersService } from "../data/services/listActiveCostCentersService";
import { ListAttachmentTypesService } from "../data/services/listAttachmentTypesService";
import { SaveAccountReceivableService } from "../data/services/saveAccountReceivableService";
import { SearchCompanyPaymentAccountsService } from "../data/services/searchCompanyPaymentAccountsService";
import { SearchCostCenterByProjectService } from "../data/services/searchCostCenterByProjectService";
import { SearchCustomerClassificationAccountByCompanyIdService } from "../data/services/searchCustomerClassificationAccountByCompanyId";
import { SearchCustomerClassificationAccountService } from "../data/services/searchCustomerClassificationAccountService";
import { SearchCustomerService } from "../data/services/searchCustomerService";
import { UpdateAccountReceivableService } from "../data/services/updateAccountReceivabletService";
import { UploadAttachmentsService } from "../data/services/uploaAttachmentService";
import { ValidateAttachmentsService } from "../data/services/validateAttachmentsService";
import { IChangeInconsistentStatusContract } from "../domain/contracts/changeInconsistentStatusContract";
import { ICheckDocumentNumberContract } from "../domain/contracts/checkDocumentNumberContract";
import { ICustomerHasClassificationAccountContract } from "../domain/contracts/customerHasClassificationAccountContract";
import { IFetchDocumentStatusContract } from "../domain/contracts/fetchDocumentStatusContract";
import { IFetchPaymentMethodsContract } from "../domain/contracts/fetchPaymentMethodsContract";
import { IGetAccountReceivableContract } from "../domain/contracts/getAccountReceivableContract";
import { IGetAttachmentsContract } from "../domain/contracts/getAttachmentsContract";
import { IGetCustomerByIdContract } from "../domain/contracts/getCustomerByIdContract";
import { IGetStorageFilebyIdContract } from "../domain/contracts/getStorageFilebyIdContract";
import { IListActiveCostCentersContract } from "../domain/contracts/listActiveCostCentersContract";
import { ISaveAccountReceivableContract } from "../domain/contracts/saveAccountReceivableContract";
import { ISearchCompanyPaymentAccountsContract } from "../domain/contracts/searchCompanyPaymentAccountsContract";
import { ISearchCostCenterByProjectContract } from "../domain/contracts/searchCostCenterByProjectContract";
import { ISearchCustomerClassificationAccountByCompanyIdContract } from "../domain/contracts/searchCustomerClassificationAccountByCompanyIdContact";
import { ISearchCustomerClassificationAccountContract } from "../domain/contracts/searchCustomerClassificationAccountContract";
import { ISearchCustomerContract } from "../domain/contracts/searchCustomerContract";
import { IUpdateAccountReceivableContract } from "../domain/contracts/updateAccountReceivableContract";
import { IUploadAttachmentsContract } from "../domain/contracts/uploadAttachmentContract";
import { IValidateAttachmentsContract } from "../domain/contracts/validateAttachmentsContract";

export type MakeAccountsReceivableForm = ICheckDocumentNumberContract &
  ICustomerHasClassificationAccountContract &
  IFetchDocumentStatusContract &
  IFetchPaymentMethodsContract &
  IGetAccountReceivableContract &
  IGetAttachmentsContract &
  IGetCustomerByIdContract &
  IGetStorageFilebyIdContract &
  IListActiveCostCentersContract &
  IListAttachmentTypesContract &
  ISaveAccountReceivableContract &
  ISearchCompanyPaymentAccountsContract &
  ISearchCostCenterByProjectContract &
  ISearchCustomerClassificationAccountContract &
  ISearchCustomerContract &
  IUpdateAccountReceivableContract &
  IUploadAttachmentsContract &
  IValidateAttachmentsContract &
  IChangeInconsistentStatusContract &
  ISearchCompanyContract &
  ISearchCompetencyContract &
  ISearchProjectContract &
  ISearchClassificationUspContract &
  ISearchCustomerClassificationAccountByCompanyIdContract;

export function makeAccountsReceivableForm(
  api: IApiService,
): MakeAccountsReceivableForm {
  const decryptService = new DecryptService();
  const getUserLocalService = new GetUserLocalService(decryptService);

  const checkDocumentNumberService = new CheckDocumentNumberService(
    getUserLocalService,
    api,
  );

  const customerHasClassificationAccountService =
    new CustomerHasClassificationAccountService(getUserLocalService, api);

  const fetchPaymentMethodsService = new FetchPaymentMethodsService(
    getUserLocalService,
    api,
  );

  const fetchDocumentStatusService = new FetchDocumentStatusService(
    getUserLocalService,
    api,
  );

  const getAccountReceivableService = new GetAccountReceivableService(
    getUserLocalService,
    api,
  );

  const getAttachmentsService = new GetAttachmentsService(
    getUserLocalService,
    api,
  );

  const getCustomerByIdService = new GetCustomerByIdService(
    getUserLocalService,
    api,
  );

  const getStorageFilebyIdService = new GetStorageFilebyIdService(
    getUserLocalService,
    api,
  );

  const listActiveCostCentersService = new ListActiveCostCentersService(
    getUserLocalService,
    api,
  );

  const listAttachmentTypesService = new ListAttachmentTypesService(
    getUserLocalService,
    api,
  );

  const saveAccountReceivableService = new SaveAccountReceivableService(
    getUserLocalService,
    api,
  );

  const searchCompanyPaymentAccountsService =
    new SearchCompanyPaymentAccountsService(getUserLocalService, api);

  const searchCostCenterByProjectService = new SearchCostCenterByProjectService(
    getUserLocalService,
    api,
  );

  const searchCustomerClassificationAccountService =
    new SearchCustomerClassificationAccountService(getUserLocalService, api);

  const searchCustomerService = new SearchCustomerService(
    getUserLocalService,
    api,
  );

  const updateAccountReceivableService = new UpdateAccountReceivableService(
    getUserLocalService,
    api,
  );

  const uploadAttachmentsService = new UploadAttachmentsService(
    getUserLocalService,
    api,
  );

  const validateAttachmentsService = new ValidateAttachmentsService(
    getUserLocalService,
    api,
  );

  const changeInconsistentStatusService = new ChangeInconsistentStatusService(
    getUserLocalService,
    api,
  );

  const searchCompanyService = new SearchCompanyService(
    getUserLocalService,
    api,
  );

  const searchCompetencyService = new SearchCompetencyService(
    getUserLocalService,
    api,
  );

  const searchProjectService = new SearchProjectService(
    getUserLocalService,
    api,
  );

  const searchClassificationUspService = new SearchClassificationUspService(
    getUserLocalService,
    api,
  );
  const setServerSideService = new SetServerSideService(
    getUserLocalService,
    api,
  );

  const searchCustomerClassificationAccountByCompanyIdService =
    new SearchCustomerClassificationAccountByCompanyIdService(
      setServerSideService,
      getUserLocalService,
      api,
    );

  return {
    checkDocumentNumber(params) {
      return checkDocumentNumberService.checkDocumentNumber(params);
    },
    customerHasClassificationAccount(companyGoupId, customerId) {
      return customerHasClassificationAccountService.customerHasClassificationAccount(
        companyGoupId,
        customerId,
      );
    },
    fetchDocumentStatus() {
      return fetchDocumentStatusService.fetchDocumentStatus();
    },
    fetchPaymentMethods() {
      return fetchPaymentMethodsService.fetchPaymentMethods();
    },
    getAccountReceivable(receivableId) {
      return getAccountReceivableService.getAccountReceivable(receivableId);
    },
    getAttachments(receivableId) {
      return getAttachmentsService.getAttachments(receivableId);
    },
    getCustomerById(providerId) {
      return getCustomerByIdService.getCustomerById(providerId);
    },
    getStorageFilebyId(storageFileId) {
      return getStorageFilebyIdService.getStorageFilebyId(storageFileId);
    },
    listActiveCostCenters(params) {
      return listActiveCostCentersService.listActiveCostCenters(params);
    },
    listAttachmentTypes() {
      return listAttachmentTypesService.listAttachmentTypes();
    },
    saveAccountReceivable(formEntity) {
      return saveAccountReceivableService.saveAccountReceivable(formEntity);
    },
    searchCompanyPaymentAccounts(
      companyGroupId,
      companyId,
      search,
      fetchMinLength,
      onlyActive,
    ) {
      return searchCompanyPaymentAccountsService.searchCompanyPaymentAccounts(
        companyGroupId,
        companyId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchCostCenterByProject(params) {
      return searchCostCenterByProjectService.searchCostCenterByProject(params);
    },
    searchCustomerClassificationAccount(
      companyGroupId,
      customerId,
      search,
      fetchMinLength,
      onlyActive,
    ) {
      return searchCustomerClassificationAccountService.searchCustomerClassificationAccount(
        companyGroupId,
        customerId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    searchCustomer(companyGroupId, search, fetchMinLength, onlyActive) {
      return searchCustomerService.searchCustomer(
        companyGroupId,
        search,
        fetchMinLength,
        onlyActive,
      );
    },
    updateAccountReceivable(formEntity) {
      return updateAccountReceivableService.updateAccountReceivable(formEntity);
    },
    uploadAttachments(params) {
      return uploadAttachmentsService.uploadAttachments(params);
    },
    validateAttachments(attachments) {
      return validateAttachmentsService.validateAttachments(attachments);
    },
    changeInconsistentStatus(formValues) {
      return changeInconsistentStatusService.changeInconsistentStatus(
        formValues,
      );
    },
    searchCompany(params) {
      return searchCompanyService.searchCompany(params);
    },
    searchCompetency(params) {
      return searchCompetencyService.searchCompetency(params);
    },
    searchProject(params) {
      return searchProjectService.searchProject(params);
    },
    searchClassificationUsp(params) {
      return searchClassificationUspService.searchClassificationUsp(params);
    },
    searchCustomerClassificationAccountByCompanyId(
      customerId,
      companyId,
      search,
    ) {
      return searchCustomerClassificationAccountByCompanyIdService.searchCustomerClassificationAccountByCompanyId(
        customerId,
        companyId,
        search,
      );
    },
  };
}
