import { InputMask } from "primereact/inputmask";
import { FocusEvent, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ITypeaheadOption } from "../../../../core/domain/entities/typeaheadOption";
import { InvalidFeedback } from "../../../../core/presentation/components/InvalidFeedback";
import { SoulTypeahead } from "../../../../core/presentation/components/SoulTypeahead";
import { useDebounceTimeAsync } from "../../../../core/presentation/hooks/useDebounceTime";
import { ProviderForm } from "../../../domain/entities/providerForm";
import { MakeProvider } from "../../../main/makeProvider";
import { ProviderType } from "../../../domain/entities/providerEntity";

interface AddressFormProps {
  providerId: string;
  useProvider: MakeProvider;
  defaultCountry: ITypeaheadOption | null;
}

interface AddressFormState {
  isLoadingZipCode: boolean;
  country: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
  state: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
  city: {
    loading: boolean;
    options: ITypeaheadOption[];
  };
}

export function AddressForm(props: AddressFormProps) {
  const { defaultCountry, useProvider } = props;

  const {
    searchCountries,
    getAddressByZipCode,
    searchBrazilianCity,
    searchBrazilianState,
  } = useProvider;

  const asyncDebounce = useDebounceTimeAsync();

  const {
    watch,
    setValue,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<ProviderForm>();

  const type = watch("type");
  const country = watch("country");

  const isForeigner = type === ProviderType.foreign;
  const isDefaultCountry = country?.rawValue === defaultCountry?.rawValue;

  const [state, setState] = useState<AddressFormState>({
    isLoadingZipCode: false,
    country: {
      options: [],
      loading: false,
    },
    state: {
      options: [],
      loading: false,
    },
    city: {
      options: [],
      loading: false,
    },
  });

  const handleSearchCountry = async (search = "") => {
    await asyncDebounce(700);

    setState(prevState => ({
      ...prevState,
      country: {
        ...prevState.country,
        loading: true,
      },
    }));

    try {
      const options = await searchCountries({
        search,
        activesOnly: true,
        payloadOptions: {
          length: 20,
          order: [{ column: 0, dir: "asc" }],
          columns: [
            {
              data: "name",
              name: "name",
              orderable: true,
              searchable: true,
              search: {
                value: "",
                regex: false,
              },
            },
          ],
        },
      });

      setState(prevState => ({
        ...prevState,
        country: {
          options,
          loading: false,
        },
        state: {
          options: [],
          loading: false,
        },
        city: {
          options: [],
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        country: {
          ...prevState.country,
          loading: false,
        },
      }));
    }
  };

  const handleSearchState = async (search = "") => {
    if (!isDefaultCountry) {
      setState({
        ...state,
        state: {
          loading: false,
          options: search
            ? [
                {
                  rawValue: "",
                  label: search,
                },
              ]
            : [],
        },
      });

      return;
    }

    await asyncDebounce(700);

    setState({
      ...state,
      state: {
        ...state.state,
        loading: true,
      },
    });

    try {
      const options = await searchBrazilianState({ search });

      setState(prevState => ({
        ...prevState,
        state: {
          options,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        state: {
          ...prevState.state,
          loading: false,
        },
      }));
    }
  };

  const handleSearchCity = async (search = "") => {
    if (!isDefaultCountry) {
      setState({
        ...state,
        city: {
          loading: false,
          options: search
            ? [
                {
                  rawValue: "",
                  label: search,
                },
              ]
            : [],
        },
      });

      return;
    }

    await asyncDebounce(700);

    setState(prevState => ({
      ...prevState,
      city: {
        ...prevState.city,
        loading: true,
      },
    }));

    const stateId = getValues("state.rawValue") as string | undefined;

    try {
      const options = await searchBrazilianCity({
        search,
        payloadOptions: { length: 20 },
        stateId: isDefaultCountry ? stateId : undefined,
      });

      setState(prevState => ({
        ...prevState,
        city: {
          options,
          loading: false,
        },
      }));
    } finally {
      setState(prevState => ({
        ...prevState,
        city: {
          ...prevState.city,
          loading: false,
        },
      }));
    }
  };

  const onBlurZipCode = async (event: FocusEvent<HTMLInputElement>) => {
    if (!isDefaultCountry) {
      return;
    }

    const zipCode = event.target.value;
    const unmaskedZipCode = zipCode.replace(/\D/g, "");

    if (unmaskedZipCode.length === 8) {
      setState(prevState => ({
        ...prevState,
        isLoadingZipCode: true,
      }));

      try {
        const viaCepMetadata = await getAddressByZipCode(unmaskedZipCode);

        if (viaCepMetadata === null) {
          setState(prevState => ({
            ...prevState,
            isLoadingZipCode: false,
          }));

          return;
        }

        setValue("city", viaCepMetadata.city, { shouldValidate: true });
        setValue("state", viaCepMetadata.state, { shouldValidate: true });
        setValue("street", viaCepMetadata.street, { shouldValidate: true });
        setValue("neighborhood", viaCepMetadata.neighborhood, {
          shouldValidate: true,
        });
      } finally {
        setState(prevState => ({
          ...prevState,
          isLoadingZipCode: false,
        }));
      }
    }
  };

  if (typeof type !== "number") {
    return null;
  }

  return (
    <div className="form-section">
      <p>Endereço</p>
      <div className="form-row">
        <label className="col-4 form-control">
          <span>
            País{" "}
            {state.country.loading && <i className="pi pi-spin pi-spinner" />}
          </span>
          <Controller
            name="country"
            defaultValue={isForeigner ? null : defaultCountry}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              return (
                <>
                  <SoulTypeahead
                    serverSide
                    openOnFocus
                    value={field.value}
                    onChange={field.onChange}
                    data-testid="sel-country"
                    options={state.country.options}
                    loading={state.country.loading}
                    placeholder="Selecione um país"
                    disabled={state.isLoadingZipCode}
                    onSearchChange={handleSearchCountry}
                    className={fieldState?.error ? "p-invalid" : ""}
                  />
                  <InvalidFeedback
                    message="Este campo é obrigatório"
                    condition={fieldState?.error?.type === "required"}
                  />
                </>
              );
            }}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-4 form-control">
          <span>
            CEP{" "}
            {state.isLoadingZipCode && <i className="pi pi-spin pi-spinner" />}
          </span>
          <Controller
            name="zipCode"
            rules={{
              required: true,
              minLength: 4,
              maxLength: 13,
            }}
            render={({ field, fieldState }) => {
              // NOTA: Este campo está com um bug visual
              // por causa de algum erro no Controller vinculado ao InputMask
              // causando uma validação ao ser renderizado a primeira vez
              // assim, ficando inválido no primeiro render, removendo a prop unmask
              // já é possível resolver o bug, mas teria que concertar outros detalhes
              return (
                <>
                  <InputMask
                    unmask
                    {...field}
                    onBlur={onBlurZipCode}
                    data-testid="txt-zipcode"
                    autoClear={isDefaultCountry}
                    disabled={state.isLoadingZipCode}
                    slotChar={isDefaultCountry ? "_" : ""}
                    className={fieldState?.error ? "isInvalid" : ""}
                    placeholder={isDefaultCountry ? "99999-999" : "CEP"}
                    mask={isDefaultCountry ? "99999-999" : "*".repeat(13)}
                  />
                  <InvalidFeedback
                    condition={fieldState?.error?.type === "required"}
                    message="Este campo é obrigatório"
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-6 form-control">
          <span>Rua</span>
          <input
            maxLength={100}
            placeholder="Rua"
            data-testid="txt-street"
            disabled={state.isLoadingZipCode}
            className={errors?.street ? "isInvalid" : ""}
            {...register("street", { required: true })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.street?.type === "required"}
          />
        </label>
        <label className="col-2 form-control">
          <span>Número</span>
          <input
            maxLength={10}
            placeholder="Número"
            data-testid="txt-placenumber"
            disabled={state.isLoadingZipCode}
            className={errors?.placeNumber ? "isInvalid" : ""}
            {...register("placeNumber", { required: true })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors?.placeNumber?.type === "required"}
          />
        </label>
      </div>
      <div className="form-row">
        <label className="col-3 form-control">
          <span>
            Estado{" "}
            {state.state.loading && <i className="pi pi-spin pi-spinner" />}
          </span>
          <Controller
            name="state"
            defaultValue={null}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              const notFoundLabel = isDefaultCountry
                ? undefined // Segue com a label padrão
                : "Digite um estado";

              return (
                <>
                  <SoulTypeahead
                    value={field.value}
                    placeholder="Digite"
                    data-testid="txt-state"
                    onChange={field.onChange}
                    serverSide={isDefaultCountry}
                    options={state.state.options}
                    loading={state.state.loading}
                    openOnFocus={isDefaultCountry}
                    disabled={state.isLoadingZipCode}
                    onSearchChange={handleSearchState}
                    notFoundOptionLabel={notFoundLabel}
                    className={fieldState?.error ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    message="Este campo é obrigatório"
                    condition={fieldState?.error?.type === "required"}
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-3 form-control">
          <span>
            Cidade{" "}
            {state.city.loading && <i className="pi pi-spin pi-spinner" />}
          </span>
          <Controller
            name="city"
            defaultValue={null}
            rules={{ required: true }}
            render={({ field, fieldState }) => {
              const notFoundLabel = isDefaultCountry
                ? undefined // Segue com a label padrão
                : "Digite uma cidade";

              return (
                <>
                  <SoulTypeahead
                    value={field.value}
                    placeholder="Digite"
                    data-testid="txt-city"
                    onChange={field.onChange}
                    serverSide={isDefaultCountry}
                    options={state.city.options}
                    loading={state.city.loading}
                    openOnFocus={isDefaultCountry}
                    disabled={state.isLoadingZipCode}
                    onSearchChange={handleSearchCity}
                    notFoundOptionLabel={notFoundLabel}
                    className={fieldState?.error ? "isInvalid" : ""}
                  />
                  <InvalidFeedback
                    message="Este campo é obrigatório"
                    condition={fieldState?.error?.type === "required"}
                  />
                </>
              );
            }}
          />
        </label>
        <label className="col-3 form-control">
          <span>Bairro</span>
          <input
            maxLength={100}
            placeholder="Bairro"
            data-testid="txt-neighborhood"
            disabled={state.isLoadingZipCode}
            className={errors?.neighborhood ? "isInvalid" : ""}
            {...register("neighborhood", { required: true })}
          />
          <InvalidFeedback
            message="Este campo é obrigatório"
            condition={errors.neighborhood?.type === "required"}
          />
        </label>
        <label className="col-3 form-control">
          <span>
            Complemento <small>(opcional)</small>
          </span>
          <input
            placeholder="Complemento"
            {...register("complement")}
            disabled={state.isLoadingZipCode}
          />
        </label>
      </div>
    </div>
  );
}
